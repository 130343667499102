
import React from 'react';
import './PlantList.css';
import printers from '../assets/printers.png';

export default function PlantList() {

	return (
		<div className='plantlist'>
								<h1>Plant List</h1>
			<div className='plantlist-content'>
				<div className='plantlist-column'>
					<h5>Workflow Suite</h5>
					<p>Objectif Lune Planet Press Connect
						<br/>
					Designer, Data Mapper, Email Module, PP Worlflow</p>
					<br/>

					<h5>Presses</h5>
					<p>2 x Canon C900 colour digital presses
					<br/>
					2 x Canon C700 colour digital presses
					<br/>
					1 x Canon VarioPrint 6160 BW digital press</p>
					<br/>

					<h5>Enclosing</h5>
					<p>Morgana FastPac DI880 mailing system
					<br/>
					Morgana 4510 pack inserter</p>
					<br/>

					<h5>Finishing</h5>
					<p>Duplo 3500 Booklet Maker, with D2200 camera controlled feeder With online trimmer and square backer
					<br />
					Plokmatic Booklet Maker
					<br/>
					Wiro Binding system
					<br/>
					Thermal glue strip binding system</p>
				</div>
				<div className='plantlist-column'>
					<img className='plantlist-printers' src={printers} alt='printers'/>
				</div>
			</div>
		</div>
	);
}