import { createStore } from 'redux'


const initialState = {
	home: {carousel:[], services:[]},
	news: {},
	join: {carousel:[], dropdown:[]},
	newsArticles: [],
	contact: {},
	settings: {},
	clients: {carousel:[]}
}


function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SETDATA':
			return action.data
    default:
      return state
  }
}


let store = createStore(reducer)


function setData(data) {
	store.dispatch({type:'SETDATA', data})
}



export default {
	store,
	setData,
	initialState
}