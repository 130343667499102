
import React, { useEffect } from 'react';
import './Sustainability.css';

import susBg from "../assets/sustain_bg.jpg";
import trustLogo from "../assets/trust_logo.png";
import co2Logo from "../assets/co2_logo.png";

export default function Sustainability() {

	return (
		<div className='sus' style={{ backgroundImage: `url(${susBg})` }}>
			<h1 style={{ color: '#3434c8', marginTop: 40 }}>Sustainability</h1>
			<div className='sus-content' style={{ maxWidth: 800, marginTop: 40 }}>
				<div className='sus-copy'>
					<b>We take environmental issues seriously, sourcing materials from reputable and responsible suppliers.</b>
					<br />
					<br />
					<b>Our suppliers have one thing in common; they are first class manufacturers of high-quality products with strong environmental credentials.</b>
					<br />
					<br />
					<b>Design to Print is signed up to a number Carbon offset projects including the World Land Trust providing an immediate and effective way to reduce our carbon footprint.</b>
					<br />
					<br />
					<b>Our factory is of a timber frame and clad construction built with sustainable timber. It has a very high SAP rating.</b>
					<br />
					<br />
					<b>The building is heated with the air source heat pumps (A+++ rated). This means the factory operates to the highest ecological standards. The EPC (Energy Performance Certificate) report shows it is in band C, among the top 20% of any buildings measured in the UK.</b>
				</div>
				<div className='sus-logos'>
					<img className='sus-logo' src={co2Logo} alt={'icon'} />
					<img className='sus-logo' src={trustLogo} alt={'icon'} />
				</div>
			</div>
		</div>
	);
}