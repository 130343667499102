
import React, { useEffect } from 'react';
import './Privacy.css';
import { useSelector } from 'react-redux';
import BlockContent from '@sanity/block-content-to-react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';


export default function Staff() {
	const settings = useSelector(state => state.settings)
	const location = useLocation();
	useEffect(() => {
		ReactGA.pageview(location.pathname);
	}, [location.pathname])

	return (
		<div className='privacy'>
			<div className='content'>
				<h1>Privacy Policy</h1>
				<div className='line' />
				{settings.privacy && <BlockContent blocks={settings.privacy} projectId='ak891pfm' dataset='production' />}
			</div>
		</div>
	);
}