
import React, { useState, useEffect } from 'react';
import './GetInTouch.css';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import { useForm, ValidationError } from '@formspree/react';


export default function GetInTouch(props) {
	const [smallScreen, setSmallScreen] = useState(false);
	const location = [51.0990694, 0.0375422];
	const [state, handleSubmit] = useForm("moqyvnje");
	const isIE = false || !!document.documentMode;

	useEffect(() => {
		const mediaQuery = window.matchMedia('(max-width: 860px)');
		mediaQuery.addListener(handleMediaQueryChange);
		handleMediaQueryChange(mediaQuery);
		return () => {
			mediaQuery.removeListener(handleMediaQueryChange);
		}
	}, [])

	function handleMediaQueryChange(mediaQuery) {
		setSmallScreen(mediaQuery.matches);
	}

	function addressClicked() {
		const url = `https://maps.google.com/?q=Design%20to%20Print%20UK%20Ltd,Units%209-10%20Riverview%20Business%20Park,Station%20Road,Forest%20Row,East%20Sussex,RH18%205FS`
		window.location.href = url;
	}

	return (
		<div className='contact'>
			<div className='contact-content'>
				<div className='contact-column'>
					<h1>Get In Touch</h1>
					<div className='contact-ways'>
						<a className='contact-link' href={'mailto:info@designtoprintuk.com'}>info@designtoprintuk.com</a>
						<br />
						<a className='contact-link' href={'tel:01342 822824'}>Tel: 01342 822824</a>
					</div>
					{state.succeeded ? <div className='contact-form-submitted'>Thank you for your message, we'll be in touch shortly!</div>
						:
						<form onSubmit={handleSubmit} className='contact-form'>
							<input
								className='contact-field'
								id="name"
								type="text"
								name="name"
								required
								placeholder="Name"
							/>
							<ValidationError
								prefix="Name"
								field="name"
								errors={state.errors}
							/>
							<input
								className='contact-field'
								id="email"
								type="email"
								name="email"
								required
								placeholder="Email Address"
							/>
							<ValidationError
								prefix="Email"
								field="email"
								errors={state.errors}
							/>
							<textarea
								className='contact-message-field'
								id="message"
								name="message"
								required
								placeholder="Message"
							/>
							<ValidationError
								prefix="Message"
								field="message"
								errors={state.errors}
							/>
							<button
								type="submit"
								disabled={state.submitting}
								className='contact-form-submit'>
								Submit
      		</button>
						</form>}
					{state.errors?.length > 0 && <div style={{ color: 'red' }}>{state.errors[0].message}</div>}
				</div>
				<div className='contact-column'>
					<h1>Find Us</h1>
					<div className='contact-info'>
						<a className='contact-link' onClick={addressClicked}>
							{'Design to Print UK Ltd\nUnits 9-10 Riverview Business Park\nStation Road, Forest Row\nEast Sussex, RH18 5FS'}
						</a>
					</div>
					{!isIE &&
						<Map center={location} zoom={15} scrollWheelZoom={false} dragging={!smallScreen}>
							<TileLayer
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
							<Marker position={location}>
								<Popup>Design to Print UK Ltd<br />Units 9-10 Riverview Business Park<br />Station Road<br />Forest Row<br />East Sussex<br />RH18 5FS</Popup>
							</Marker>
						</Map>}
				</div>
			</div>
		</div>
	);
}