
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './WhatWeDo.css';
import WWDBlock from './WWDBlock';
import * as Scroll from 'react-scroll';

import block1 from '../assets/wwd/wwd-block-1.jpg';
import block2 from '../assets/wwd/wwd-block-2.jpg';
import block3 from '../assets/wwd/wwd-block-3.jpg';

export default function WhatWeDo() {
	const params = useParams();
	var scroll = Scroll.scroller;

	useEffect(() => {
		if (params.slug) {
				scroll.scrollTo(params.slug, {
					duration: 300,
					delay: 100,
					smooth: true,
					offset: -110,
				});
		}
	}, [params.slug])

	return (
		<div className='wwd'>
			<WWDBlock 
				light
				name='print'
				title='Digtal Print and Finishing' 
				body={'Our new Canon Colour Digital Presses give us huge capacity for full colour output, whilst our VarioPrint BW Press is exceptionally fast achieved by printing on both sides of the sheet in one pass.\n\nThese presses take print to the next level with high resolution printing at high speed and are built for reliability.\n\nUsing the latest technology allows us to print on different paper stocks on the go, bringing together covers and text, ideal for variable projects.\n\nOur Duplo Digital Booklet Maker is fitted with barcode scanners enabling us to finish packs with variable paginations. It also has an online square backer, creating professional looking square bound booklets.'}
				image={block1}/>
			<WWDBlock  
				name='mailing'
				title='Mailing and Distribution'
				body={'The Morgana FastPac Mailing System has seven feeder trays and can fill 12mm worth of inserts. It handles all envelope sizes from C4 down to DL. The mailing line has a capacity of approximately 5,000 packs per hour.\n\nWe use several mailing companies including Royal Mail, Whistl and UK Mail to offer the most competitive service and apply discounts to larger mailings keeping costs as low as possible.\n\nWe use secure courier services. On larger projects we have organised distribution of printed surveys to over 300 schools throughout the UK and arranged for collection of these once completed.\n\nOur workflow tool can be used to direct which documents are to be printed and which are to be sent electronically.'}
				image={block2}
				imageLeft/>
			<WWDBlock 
				light
				name='workflows'
				title='Creating Workflows'
				body={'With PlanetPress Connect we can automate document workflow. Think of a workflow as being a pipe. Data and documents are falling down the pipe and we can decide what is going to happen to those documents\n\nTaking client PDFs without changing the originals, the files pass through the workflow. Here we can add variables like barcodes, mailing marks or images and even whole pages. Using Connect, we can do clever things like merging the documents with data to add names and addresses.\n\nIf the document is to be printed, we can impose the pages for the press.\n\nWe can also direct which documents are to be printed and which are to be sent electronically via direct email or archived to an FTP server.'}
				image={block3}/>
		</div>
	);
}