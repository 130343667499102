import React, { useState, useEffect } from 'react';
import './App.css';
import {
	HashRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import ScrollToTop from './common/ScrollToTop';
import Header from './Header';
import Home from './home/Home';
import WhatWeDo from './whatwedo/WhatWeDo';
import CaseStudies from './casestudies/CaseStudies';
import GetInTouch from './getintouch/GetInTouch';
import Sustainability from './sustainability/Sustainability';
import Footer from './common/Footer';
import PlantList from './plantlist/PlantList';

import { Provider } from 'react-redux';
import Store from './modules/store';
import Privacy from './privacy/Privacy';


export default function App() {
	const [navVisible, setNavVisible] = useState(true);
	const [smallScreen, setSmallScreen] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia('(max-width: 860px)');
		mediaQuery.addListener(handleMediaQueryChange);
		handleMediaQueryChange(mediaQuery);
		return () => {
			mediaQuery.removeListener(handleMediaQueryChange);
		}
	}, [])

	function handleMediaQueryChange(mediaQuery) {
		setSmallScreen(mediaQuery.matches);
		if (mediaQuery.matches) {
			setNavVisible(false);
		}
	}

	function toggleNav() {
		setNavVisible(!navVisible);
	}

	function navigate() {
		if (smallScreen) {
			setNavVisible(false);
		}
	}

	return (
		<Provider store={Store.store}>
				<Router>
					<ScrollToTop />
					<div className="app">
						<Header
							toggleNav={toggleNav}
							navigate={navigate}
							smallScreen={smallScreen}
							navVisible={navVisible}
						/>
						<div className={"app-content-no-banner"}>
							<Switch>
								<Route exact path="/">
									<Home />
								</Route>
								<Route path="/what-we-do/:slug" component={WhatWeDo} />
								<Route exact path="/what-we-do">
									<WhatWeDo />
								</Route>
								<Route path="/case-studies/:slug" component={CaseStudies} />
								<Route exact path="/case-studies">
									<CaseStudies />
								</Route>
								<Route path="/contact">
									<GetInTouch />
								</Route>
								<Route path="/sustainability">
									<Sustainability />
								</Route>
								<Route path="/privacy-policy">
									<Privacy />
								</Route>
								<Route path="/plant-list">
									<PlantList />
								</Route>
							</Switch>
						</div>
					</div>
					<Footer />
				</Router >
		</Provider>
	);
}