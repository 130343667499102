
import React from 'react';
import { Link } from 'react-router-dom'
import './Footer.css';
import ppLogo from '../assets/footer/pp_connect.png';
import olLogo from '../assets/footer/ol_button.png';
import linkedinLogo from '../assets/footer/linkedin_button.png';
import isoLogo from '../assets/footer/iso_logo.png';


export default function Footer(props) {


	function addressClicked() {
		const url = `https://maps.google.com/?q=Design%20to%20Print%20UK%20Ltd,Units%209-10%20Riverview%20Business%20Park,Station%20Road,Forest%20Row,East%20Sussex,RH18%205FS`
		window.location.href = url;
	}

	return (
		<div className='footer'>
			<div className='footer-content-container'>
				<div className='footer-content'>
					<div className='footer-chunk'>
						<Link className='footer-link' to={'/contact'}>Get In Touch</Link>
						<br />
						<Link className='footer-link' to={'/sustainability'}>Sustainability</Link>
						<br />
						<Link className='footer-link' to={'/plant-list'}>Plant List</Link>
						<br />
						<a className='footer-link' href={'https://sunmore.s3-eu-west-1.amazonaws.com/D2P_Terms_and_Conditions.pdf'}>Terms & Conditions</a>
						<br />
						<a className='footer-link' href={'https://sunmore.s3.eu-west-1.amazonaws.com/D2P_ISO27001_Certificate.pdf'}>ISO27001 Certification</a>
						<br />
						<a className='footer-link' href={'https://sunmore.s3.eu-west-1.amazonaws.com/D2P_IS01_Information+Security+Policy.pdf'}>Information Security Policy</a>
						<div className='footer-block-horizontal'>
							<a href={'https://planetpress.objectiflune.com/en/'}>
								<img className='footer-pp-button' src={ppLogo} alt={'planet press'} />
							</a>
							<a href={'https://www.objectiflune.com/en/products'}>
								<img className='footer-pp-button' src={olLogo} alt={'objectif lune'} />
							</a>
						</div>
					</div>
					<div className='footer-chunk'>
						<a className='footer-link' onClick={addressClicked}>
							{'Design to Print UK Ltd\nUnits 9-10 Riverview Business Park\nStation Road\nForest Row\nEast Sussex\nRH18 5FS'}
						</a>
						<br />
						<br />
						<div className='footer-block-horizontal' style={{ marginTop: 0 }}>
							<div>
								<a className='footer-link' href={'tel:01342 822824'}>Tel: 01342 822824</a>
								<br />
								<a className='footer-link' href={'mailto:info@designtoprintuk.com'}>info@designtoprintuk.com</a>
							</div>
							<a href={'https://www.linkedin.com/in/rob-dewhurst-01729b19/'}>
								<img className='footer-linkedin-button' src={linkedinLogo} alt={'linkedin'} />
							</a>
						</div>
						<img className='footer-iso-button' src={isoLogo} alt={'iso certified'} />
					</div>
				</div>
				<div className='footer-copyright'>
					<p className='footer-copyright-text'>Copyright - Design to Print UK Ltd. 2021</p>
				</div>
			</div>
		</div>
	);
}

