
import React from 'react';
import { useHistory } from 'react-router-dom';
import './Home.css';
import HomeWWDItem from './HomeWWDItem';
import HomeCaseStudy from './HomeCaseStudy';
import Hero from './HomeWaveHero';

import wwdIcon1 from '../assets/wwd/wwd_icon_1.png';
import wwdIcon2 from '../assets/wwd/wwd_icon_2.png';
import wwdIcon3 from '../assets/wwd/wwd_icon_3.png';

import csIcon1 from '../assets/cs/cs_icon_1.png';
import csIcon2 from '../assets/cs/cs_icon_2.png';
import csIcon3 from '../assets/cs/cs_icon_3.png';
import csIcon4 from '../assets/cs/cs_icon_4.png';

import susBg from "../assets/sustain_bg.jpg";
import trustLogo from "../assets/trust_logo.png";
import co2Logo from "../assets/co2_logo.png";

import { useForm, ValidationError } from '@formspree/react';

export default function Home() {
	const [state, handleSubmit] = useForm("moqyvnje");
	const history = useHistory();


	function onCSClick(key) {
		history.push(`/case-studies/${key}`);
	}

	function onWWDClick(key) {
		history.push(`/what-we-do/${key}`);
	}

	return (
		<div id='home' className='home'>
			<Hero />
			<div className='home-block'>
				<h1>What We Do</h1>
				<div className='home-block-horizontal' style={{maxWidth: 950}}>
					<HomeWWDItem data={{ title: 'Digital Printing', key: 'print', icon: wwdIcon1, info: 'Using the latest technology, digital printing and finishing is at the heart of what we do' }} onClick={onWWDClick}/>
					<HomeWWDItem data={{ title: 'Mailing and Distribution', key: 'mailing', icon: wwdIcon2, info: 'Most of our work is ultimately distributed to end users either by mail or electronically' }} onClick={onWWDClick}/>
					<HomeWWDItem data={{ title: 'Workflows', icon: wwdIcon3, key: 'workflows', info: 'Our Connect Software creates workflows for complex reporting and document distribution' }} onClick={onWWDClick}/>
				</div>
			</div>
			<div className='home-block' style={{ backgroundColor: '#cce9f4' }}>
				<h1 style={{ color: '#3434c8' }}>Case Studies</h1>
				<div className='home-block-horizontal'>
					<HomeCaseStudy data={{ title: 'Client Valuation Reports', icon: csIcon1, key: 'valuations' }} onClick={onCSClick}/>
					<HomeCaseStudy data={{ title: 'Invoice Confirmations', icon: csIcon2, key: 'confirmation-sheets' }} onClick={onCSClick}/>
					<HomeCaseStudy data={{ title: 'Passenger Rail Survey', icon: csIcon3, key: 'rail-survey' }} onClick={onCSClick}/>
					<HomeCaseStudy data={{ title: `Scottish Schools\nSubstance Use Survey`, icon: csIcon4, key: 'substance-survey' }} onClick={onCSClick}/>
				</div>
			</div>
			<div className='home-block'>
				{!state.succeeded && <h1>Please Get In Touch</h1>}
				{state.succeeded ? <div className='home-form-submitted'>Thank you for your message, we'll be in touch shortly!</div>
					:
					<form onSubmit={handleSubmit} className='home-form'>
						<div className='home-form-fields'>
							<input
								className='home-field'
								id="name"
								type="text"
								name="name"
								required
								placeholder="Name"
							/>
							<ValidationError
								prefix="Name"
								field="name"
								errors={state.errors}
							/>
							<input
								className='home-field'
								id="email"
								type="email"
								name="email"
								required
								placeholder="Email Address"
							/>
							<ValidationError
								prefix="Email"
								field="email"
								errors={state.errors}
							/>
						</div>
						<textarea
							className='home-message-field'
							id="message"
							name="message"
							required
							placeholder="Message"
						/>
						<ValidationError
							prefix="Message"
							field="message"
							errors={state.errors}
						/>
						<button
							type="submit"
							disabled={state.submitting}
							className='home-form-submit'>
							Submit
      		</button>
					</form>}
				{state.errors?.length > 0 && <div style={{ color: 'red' }}>{state.errors[0].message}</div>}
			</div>
			<div className='home-block' style={{ backgroundImage: `url(${susBg})` }}>
				<h1 style={{ color: '#3434c8' }}>Sustainability</h1>
				<div className='home-block-sus' style={{ maxWidth: 800, marginTop: 40 }}>
					<div className='home-sus-copy'>
						<b>We take environmental issues seriously, sourcing materials from reputable and responsible suppliers.</b>
						<br />
						<br />
						<b>Our suppliers have one thing in common; they are first class manufacturers of high-quality products with strong environmental credentials.</b>
						<br />
						<br />
						<b>Design to Print is signed up to a number carbon offset projects including the World Land Trust providing an immediate and effective way to reduce our carbon footprint.</b>
						<br />
						<br />
						<b>Our factory is of a timber frame and clad construction built with sustainable timber. It has a very high SAP rating.</b>
						<br />
						<br />
						<b>The building is heated with the air source heat pumps (A+++ rated). This means the factory operates to the highest ecological standards. The EPC (Energy Performance Certificate) report shows it is in band C, among the top 20% of any buildings measured in the UK.</b>
					</div>
					<div className='home-sus-logos'>
						<img className='home-sus-logo' src={co2Logo} alt={'icon'} />
						<img className='home-sus-logo' src={trustLogo} alt={'icon'} />
					</div>
				</div>
			</div>
		</div>
	);
}