
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useParams, useLocation } from 'react-router-dom';
import './CaseStudies.css';
import CSBlock from './CSBlock';
import * as Scroll from 'react-scroll';

import block1 from '../assets/cs/cs-block-1.png';
import block1Alt from '../assets/cs/cs-block-1-alt.png';
import block2 from '../assets/cs/cs-block-2.png';
import block3 from '../assets/cs/cs-block-3.png';
import block4 from '../assets/cs/cs-block-4.png';


export default function CaseStudies() {
	const location = useLocation();
	const params = useParams();
	var scroll = Scroll.scroller;

	useEffect(() => {
		ReactGA.pageview(location.pathname);
	}, [location.pathname])

	useEffect(() => {
		if (params.slug) {
				scroll.scrollTo(params.slug, {
					duration: 300,
					delay: 100,
					smooth: true,
					offset: -110,
				});
		}
	}, [params.slug])

	return (
		<div className='cs'>
			<CSBlock
				name={'valuations'} 
				title='Valuation Reports'
				body={'Currently we produce valuation packs for a number of stockbrokers.\n\nThe example on the right shows the typical structure of a portfolio valuation. The back-office client reports arrive as a batch of PDFs and are then run through the workflow.\n\nHere they have pages added like a personalised letter, a quarterly newsletter as well as front and back covers. As the PDFs pass through the workflow the final document is merged with an Excel file to pick up the names addresses for the front cover.\n\nThey are printed on light and heavy paper stocks for the text and covers, in sequence, and bound into square backed booklets. Lastly, they are enveloped, batch checked and posted directly to the account holders.\n'}
				image={block1}
				altImage={block1Alt}/>
			<CSBlock 
				light 
				name={'confirmation-sheets'}
				title='Invoice Confirmation Sheets'
				body={'For some clients we print Invoice/Order confirmation sheets, Contract Notes and other similar regular documents that require frequent mailings.\n\nThese arrive via FTP and are collected daily by the workflow software. Here they are mail sorted, Mail Marked and insertion codes are added. The insertion codes add extra security to make sure only the correct sheets are inserted into the envelopes.\n\nBy mail sorting the documents as they pass through the workflow, we are able to offer more competitive postal rates than those generally available.'}
				image={block2}
				imageLeft/>
			<CSBlock 
				name={'rail-survey'}
				title='Passenger Rail Survey'
				body={'We have for several years produce a National Rail Passenger Survey (NRPS), covering train routes throughout the UK.\n\nThe project involves producing an 8-page questionnaire with a unique barcode to identify the exact location, time and date the survey was filled out. The questions are changed depending on the destination of the train.\n\nEach of these surveys comes with a reply-paid envelope so the passenger can fill out the questionnaire, put it in the envelope and post back to us for scanning.\n\nWe produce approximately 300,000 questionnaires per year. The results of these are scanned in and the data used to check the performance of the UK Rail Network System and its franchised routes.'}
				image={block3}
				imageLeft/>
			<CSBlock 
				light
				name={'substance-survey'}
				title='Substance Use Survey'
				body={'This project involves surveying students from over 300 schools all over Scotland.\n\nEach school was sent class packs containing student questionnaires with unique barcodes. These codes enabled our client to track which school and which class the survey was from whilst allowing the pupil to remain anonymous.\n\nIn addition the questionnaires the packs also contained an attendance sheet, consent letters for parents, instruction sheets, blank envelopes allowing students to seal into envelope before returning to the teachers, self-addressed envelopes for pupils who had not attended the class on that day. Also, an addressed collection bag for the completed questionnaires to be packed in ready for collection by our courier for return to us. A complicated project to manage all round.\n\nThe survey is carried out once every two years on average.'}
				image={block4}
				imageLeft/>
		</div>
	);
}