
import React, { useState } from 'react';
import './HomeCaseStudy.css';

export default function HomeCaseStudy(props) {
	const [hover, setHover] = useState(false);

	return (
		<div className='home-cs-item'
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={() => props.onClick(props.data.key)}>
			<img className='h-cs-icon' src={props.data?.icon ?? undefined} alt={'icon'} />
			<div className='h-cs-title'>{props.data?.title}</div>
			<div className='h-cs-info' style={{ color: hover ? '#ec008c' : '#3434c8' }}>Find Out More {hover && '›'}</div>
		</div>
	);
}