
import React, { useState } from 'react';
import './HomeWWDItem.css';

export default function HomeWWDItem(props) {
	const [hover, setHover] = useState(false);
	
  return (
		<div className='home-wwd-item' 
			onMouseEnter={() => setHover(true)} 
			onMouseLeave={() => setHover(false)}
			onClick={() => props.onClick(props.data?.key)}>
			<div className='h-wwd-title'>{props.data?.title}</div>
			<img className='h-wwd-icon' src={props.data?.icon ?? undefined} alt={'icon'} />
      <div className='h-wwd-info'>{props.data?.info}</div>
			<div className='h-wwd-info' style={{color: hover? '#ec008c' : '#ffffff'}}>Find Out More {hover && '›'}</div>
		</div>
  );
}