
import React, { useEffect, useState } from 'react';
import './HomeWaveHero.css';
import wave from '../assets/wave_3.jpg';
import text1 from '../assets/wave_text_1.png';
import text2 from '../assets/wave_text_2.png';
import text3 from '../assets/wave_text_3.png';
import text4 from '../assets/wave_text_4.png';
import FadeIn from 'react-fade-in';

export default function HomeJoin(props) {
  const [index, setIndex] = useState(0)
  
  function rotate() {
    setInterval(() => {
      setIndex(index => {
        if (index > 2) return 0;
        return index + 1;
      });
    }, 7500);
  }

  useEffect(() => {
    rotate();
  }, [])


  return (
    <div className='wave-hero'>
      <img className="wave-hero-bg" src={wave} alt='logo'/>
      <FadeIn className="wave-hero-text-container" visible={index == 0}>
        <img className="wave-hero-bg" src={text1} alt='title'/>
      </FadeIn>
      <FadeIn className="wave-hero-text-container" visible={index == 1}>
        <img className="wave-hero-bg" src={text2} alt='title'/>
      </FadeIn>
      <FadeIn className="wave-hero-text-container" visible={index == 2}>
        <img className="wave-hero-bg" src={text3} alt='title'/>
      </FadeIn>
      <FadeIn className="wave-hero-text-container" visible={index == 3}>
        <img className="wave-hero-bg" src={text4} alt='title'/>
      </FadeIn>
    </div>
  );
}