import React from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import './Header.css';
import logo from '../app/assets/branding/logo_blue.png';

export default function Header(props) {
	const history = useHistory();


	return (
		<div className='header'>
			<div className='header-content'>
				<div className='header-logo'>
					<NavLink onClick={props.navigate} exact to="/"><img className="header-logo-name" src={logo} alt='logo' /></NavLink>
					<div className='header-burger' onClick={props.toggleNav} >
						{props.navVisible ? <IoMdClose size={30} /> : <IoMdMenu size={30} />}
					</div>
				</div>
				<CSSTransition
					in={props.navVisible || !props.smallScreen}
					timeout={350}
					classNames='nav-animation'
					unmountOnExit>
					<nav className='header-nav'>
						<NavLink onClick={props.navigate} className='header-link' activeClassName='header-link-active' to="/what-we-do">WHAT WE DO</NavLink>
						<NavLink onClick={props.navigate} className='header-link' activeClassName='header-link-active' to="/case-studies">CASE STUDIES</NavLink>
						<NavLink onClick={props.navigate} className='header-link' activeClassName='header-link-active' to="/sustainability">SUSTAINABILITY</NavLink>
						<NavLink onClick={props.navigate} className='header-link' activeClassName='header-link-active' to="/contact">GET IN TOUCH</NavLink>
					</nav>
				</CSSTransition>
			</div>
		</div>
	);
}