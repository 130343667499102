
import React, { useState, useEffect } from 'react';
import './WWDBlock.css';
import * as Scroll from 'react-scroll';
import defaultImage from '../assets/wwd/wwd-block-1.jpg';


export default function WhatWeDo(props) {
  const [smallScreen, setSmallScreen] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia('(max-width: 760px)');
		mediaQuery.addListener(handleMediaQueryChange);
		handleMediaQueryChange(mediaQuery);
		return () => {
			mediaQuery.removeListener(handleMediaQueryChange);
		}
	}, [])

	function handleMediaQueryChange(mediaQuery) {
		setSmallScreen(mediaQuery.matches);
	}


  const colourClass = props.light ? 'wwd-block-light' : 'wwd-block-dark';

  const Img = (intProps) => {
    return <img className={`wwd-block-img ${intProps.className}`} src={props.image ?? defaultImage} alt='What We Do' />
  }

  return (
    <Scroll.Element name={props.name} className={`wwd-block ${colourClass}`} style={{color: props.light? '#3434c8': 'white'}}>
      <h1 style={{color: props.light? '#3434c8': 'white'}}>{props.title}</h1>
      <div className='wwd-block-content'>
        {props.imageLeft && !smallScreen && <Img className='wwd-block-img-left' />}
        <b>{props.body}</b>
        {(!props.imageLeft || smallScreen) && <Img className='wwd-block-img-right' />}
      </div>
    </Scroll.Element>
  );
}